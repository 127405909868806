import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'
import VrtLogo from '/public/static/vrt-logos/vrt/svg/vrt-color.svg'
import Link from 'next/link'
import { ArrowRightIcon } from '@heroicons/react/solid'

export const LandingPageHeader = (): JSX.Element => {
	const navigation = [
		{ name: 'Home', href: '/' },
		{ name: 'Partner worden', href: '/partner-worden' },
		{ name: 'Contact', href: '/contact' },
	]
	return (
		<Popover className="relative z-10">
			<div className="mx-auto px-4 sm:px-6 bg-white border-b-2 border-b-white rounded-b-3xl drop-shadow-md">
				<div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
					<div className="flex justify-start lg:w-0 lg:flex-1">
						<Link href={'/'} passHref={true} className="m-0 ">
							<div>
								<VrtLogo
									className={'h-6 hover:cursor-pointer'}
								/>
							</div>
						</Link>
					</div>
					<div className="-mr-2 -my-2 md:hidden">
						<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
							<span className="sr-only">Open menu</span>
							<MenuIcon className="h-6 w-6" aria-hidden="true" />
						</Popover.Button>
					</div>
					<Popover.Group
						as="nav"
						className="hidden md:flex space-x-10"
					>
						{navigation.map((item: any) => (
							<Link href={item.href} key={item.name}>
								<a className="text-base font-medium text-gray-500 hover:text-gray-900">
									{item.name}
								</a>
							</Link>
						))}
					</Popover.Group>
					<div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
						<Link href={'/portaal/aanbod'}>
							<button
								type="button"
								className="inline-flex items-center rounded-md border border-vrt-pink rounded-md px-4 py-2 gap-x-3 font-medium text-vrt-pink hover:bg-vrt-pink hover:text-white"
							>
								Naar het portaal
								<ArrowRightIcon className={'h-5'} />
							</button>
						</Link>
					</div>
				</div>
			</div>

			<Transition
				as={Fragment}
				enter="duration-200 ease-out"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="duration-100 ease-in"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<Popover.Panel
					focus
					className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
				>
					<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
						<div className="pt-5 pb-6 px-5">
							<div className="flex items-center justify-between">
								<div>
									<VrtLogo className={'h-6'} />
								</div>
								<div className="-mr-2">
									<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
										<span className="sr-only">
											Close menu
										</span>
										<XIcon
											className="h-6 w-6"
											aria-hidden="true"
										/>
									</Popover.Button>
								</div>
							</div>
							<div className="mt-6">
								<nav className="grid gap-y-8">
									{navigation.map((item: any) => (
										<Link href={item.href} key={item.name}>
											<a className="text-base font-medium text-gray-500 hover:text-gray-900">
												{item.name}
											</a>
										</Link>
									))}
								</nav>
							</div>
						</div>
						<div className="py-6 px-5 space-y-6">
							<div>
								<Link href={'/portaal/aanbod'}>
									<button
										type="button"
										className="w-full inline-flex items-center justify-center rounded-md border border-vrt-pink rounded-md px-4 py-2 gap-x-3 font-medium text-vrt-pink hover:bg-vrt-pink hover:text-white"
									>
										Naar het portaal
										<ArrowRightIcon className={'h-5'} />
									</button>
								</Link>
							</div>
						</div>
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	)
}
