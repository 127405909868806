import type { NextPage } from 'next'
import { Footer } from '../components/Footer/Footer'
import { VrtBrandLogoBar } from '../components/VrtBrandLogoBar/VrtBrandLogoBar'
import { LandingPageHeader } from '../components/Header/LandingPageHeader'
import SEO from '../components/SEO'
import Link from 'next/link'
const Home: NextPage = () => {
	return (
		<>
			<SEO
				title={'Kwalitatieve VRT-content op jouw platform'}
				description={
					'Krijg toegang tot clips uit de populairste VRT-programma’s en verrijk je klantenervaring met sterke mediacontent.'
				}
			/>
			<LandingPageHeader />
			<div className={'-mt-10'}>
				<aside className="overflow-hidden bg-[url(/heroImage.jpeg)] bg-center bg-no-repeat bg-cover">
					<div className="p-8 md:p-12 lg:px-16 md:py-24 lg:py-36 bg-black/20">
						<div className="text-center">
							<h1
								className="font-bold text-9xl 2xl:text-[200px] text-transparent"
								style={{
									WebkitTextStroke: '2px',
									WebkitTextStrokeColor: 'white',
								}}
							>
								Open Media
							</h1>
						</div>
					</div>
				</aside>
			</div>
			<section className="container max-w-7xl mx-auto px-6">
				<div className="py-8 lg:py-16 mx-auto text-center text-max-dark-blue">
					<div className="pb-4">
						<h1 className="text-4xl font-semibold md:text-5xl lg:text-6xl ">
							Kwalitatieve VRT-content op jouw platform
						</h1>
						<p className="text-2xl font-light lg:text-2xl sm:px-16 xl:px-48 text-max-dark-blue py-10">
							VRT stelt via het Open Media-platform een deel van
							haar content ter beschikking zodat partners op een
							eenvoudige en kostenefficiënte manier hun apps of
							sites kunnen verrijken met clips of nieuws.
						</p>
					</div>
					<div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-6 md:space-y-0 text-center">
						<div>
							<h3 className="mb-2 text-2xl font-bold">
								Dagelijks nieuwe content
							</h3>
							<p className="font-light text-xl dark:text-gray-400">
								Krijg dagelijks toegang tot actuele content die
								er toe doet.
							</p>
						</div>
						<div>
							<h3 className="mb-2 text-2xl font-bold ">
								VRT-kwaliteitslabel
							</h3>
							<p className="font-light text-xl">
								VRT selecteert en biedt de meest interessante
								content aan.
							</p>
						</div>
						<div>
							<h3 className="mb-2 text-2xl font-bold ">
								Op jouw maat
							</h3>
							<p className="font-light text-xl">
								Eenvoudig te integreren in je eigen omgeving via
								onze embed codes en API’s.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section className="container max-w-7xl mx-auto px-6 bg-white py-6">
				<div className="text-center pb-5">
					<span className="font-semibold text-gray-400 uppercase">
						Onze merken
					</span>
				</div>
				<VrtBrandLogoBar color={'color'} />
			</section>

			<section>
				<div className="px-4 py-16 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
					<div className="grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2">
						<div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:h-full lg:order-last">
							<img
								className="absolute inset-0 object-cover w-full h-full"
								src="/static/images/media-image-vrt.jpeg"
								alt="VRT Open Media"
							/>
						</div>

						<div className="lg:py-24">
							<h2 className="text-3xl font-bold sm:text-4xl">
								Dagelijks aanbod aan short form video
							</h2>

							<p className="mt-4 text-max-dark-blue text-lg font-light">
								Krijg toegang tot clips uit de populairste
								VRT-programma’s en verrijk je klantenervaring
								met sterke mediacontent.
							</p>

							<Link href={'/partner-worden'}>
								<a className="inline-flex items-center px-8 py-3 mt-8 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring">
									<span className="font-medium">
										{' '}
										Partner worden{' '}
									</span>
								</a>
							</Link>
						</div>
					</div>
				</div>
				<div className="px-4 py-8 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
					<div className="grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2">
						<div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:h-full lg:order-first">
							<img
								className="absolute inset-0 object-cover w-full h-full"
								src="/static/images/media-image-vrtnws.jpeg"
								alt="Kunnen zijn wie je bent"
							/>
						</div>

						<div className="lg:py-24">
							<h2 className="text-3xl font-bold sm:text-4xl">
								De hoogtepunten uit het nieuws
							</h2>

							<p className="mt-4 text-max-dark-blue text-lg font-light">
								Bied je klanten de hoogtepunten van VRT NWS aan,
								het meest betrouwbare nieuwsmerk van het land.
								Integreer de feed van VRT NWS, Sporza, Karrewiet
								of nws.nws.nws in je app of op je site!
							</p>

							<Link href={'/partner-worden'}>
								<a className="inline-flex items-center px-8 py-3 mt-8 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring">
									<span className="font-medium">
										{' '}
										Partner worden{' '}
									</span>
								</a>
							</Link>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	)
}

export default Home
